import React, {Component, Fragment} from 'react';
import "./build/css/main.css";
import piep from "./audio/piep.mp3";
import sound_30 from "./audio/30.mp3";
import sound_25 from "./audio/25.mp3";
import sound_20 from "./audio/20.mp3";
import sound_15 from "./audio/15.mp3";
import sound_10 from "./audio/10.mp3";
import sound_9 from "./audio/9.mp3";
import sound_8 from "./audio/8.mp3";
import sound_7 from "./audio/7.mp3";
import sound_6 from "./audio/6.mp3";
import sound_5 from "./audio/5.mp3";
import sound_4 from "./audio/4.mp3";
import sound_3 from "./audio/3.mp3";
import sound_2 from "./audio/2.mp3";
import sound_1 from "./audio/1.mp3";
import hupe from "./audio/hupe.mp3";
import {Howl, Howler} from "howler";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seconds: 120,
            soundTenSeconds: false,
            soundThirtySeconds: false,
            leadTime: 10,
            appActive: false,
            timerTime: 0,
            color: {backgroundColor: "#e05050"}
        };

        this.handleSecondChange = this.handleSecondChange.bind(this);
        this.handleLeadTimeChange = this.handleLeadTimeChange.bind(this);
        this.handleSoundTenSecondsChange = this.handleSoundTenSecondsChange.bind(this);
        this.handleSoundThirtySecondsChange = this.handleSoundThirtySecondsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.tick = this.tick.bind(this);

        this.sound = new Howl({
            src: [piep],
            /*sprite: {
                hupe: [0, 1944.0136054421768],
                piep: [3000, 574.69387755102]
            },*/
            volume: 1,
            onload: function(){
                console.info('Sounds loaded!');
            },
            onplay: function(){
                console.info('Sounds playing!');
            }
        });

        var hupeSound = new Howl({
            src: [hupe]
        });

        this.hupe = hupeSound;

        this.sounds = {
            1: new Howl({
                src: [sound_1]
            }),
            2: new Howl({
                src: [sound_2]
            }),
            3: new Howl({
                src: [sound_3]
            }),
            4: new Howl({
                src: [sound_4]
            }),
            5: new Howl({
                src: [sound_5]
            }),
            6: new Howl({
                src: [sound_6]
            }),
            7: new Howl({
                src: [sound_7]
            }),
            8: new Howl({
                src: [sound_8]
            }),
            9: new Howl({
                src: [sound_9]
            }),
            10: new Howl({
                src: [sound_10]
            }),
            15: new Howl({
                src: [sound_15]
            }),
            20: new Howl({
                src: [sound_20]
            }),
            25: new Howl({
                src: [sound_25]
            }),
            30: new Howl({
                src: [sound_30]
            })
        }
    }

    componentWillMount() {
        var me = this;
        document.addEventListener("keyup", function (event) {
            if(event.key === "s") {
                me.startTimer();
            }
            if(event.key === "r") {
                me.resetTimer();
            }
        })
    }

    handleSecondChange(event) {
        this.setState({seconds: parseInt(event.target.value)});
    }

    handleLeadTimeChange(event) {
        this.setState({leadTime: parseInt(event.target.value)});
    }

    handleSoundTenSecondsChange(event) {
        this.setState({soundTenSeconds: event.target.checked});
    }

    handleSoundThirtySecondsChange(event) {
        this.setState({soundThirtySeconds: event.target.checked});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({appActive: true, timerTime: this.state.seconds + this.state.leadTime});
    }

    startTimer(event) {
        if(!this.timer) {
            this.timer = setInterval(this.tick, 1000);
            this.sound.play();
        }
    }

    tick() {
        if(this.state.timerTime > 0) {
            this.setState({timerTime: this.state.timerTime - 1});
        }

        if(this.state.timerTime === this.state.seconds && this.state.timerTime >= 30) {
            this.setState({color: {backgroundColor: "#4fe241"}});
            this.sound.play();
        }

        if(this.state.timerTime === 30) {
            this.setState({color: {backgroundColor: "#e2e041"}});
        }

        if(this.state.timerTime === 0) {
            this.setState({color: {backgroundColor: "#e05050"}});
            this.resetTimer();
        }

        if(this.state.timerTime <= 30 && this.state.timerTime > 0) {
            if(this.state.soundTenSeconds && this.state.timerTime <= 10) {
                this.sounds[this.state.timerTime].play();
            }
           else if(this.state.soundThirtySeconds && this.state.timerTime % 5 === 0) {
                this.sounds[this.state.timerTime].play();
            }
        }
    }

    resetTimer() {
        clearInterval(this.timer);
        this.timer = undefined;
        this.setState({timerTime: this.state.seconds + this.state.leadTime, color: {backgroundColor: "#e05050"}});
        this.hupe.play();
    }

    render() {
        return (
            <Fragment>
                {!this.state.appActive &&
                <form onSubmit={this.handleSubmit} className="settings-form">
                    <div className="input-field">
                        <label htmlFor="seconds">Zeit in Sekunden</label>
                        <input type="number" value={this.state.seconds} onChange={this.handleSecondChange} id="seconds" />
                    </div>
                    <div className="input-field">
                        <label htmlFor="lead-time">Vorlaufzeit</label>
                        <input type="number" value={this.state.leadTime} onChange={this.handleLeadTimeChange} id="lead-time" />
                    </div>
                    <p>
                        <label htmlFor="sound-ten-seconds">
                            <input type="checkbox" value={this.state.soundTenSeconds} onChange={this.handleSoundTenSecondsChange} id="sound-ten-seconds" />
                            <span>Sekundenpiepston ab 10 Sekunden</span>
                        </label>
                    </p>
                    <p>
                        <label htmlFor="sound-thirty-seconds">
                            <input type="checkbox" value={this.state.soundThirtySeconds} onChange={this.handleSoundThirtySecondsChange} id="sound-thirty-seconds" />
                            <span>5 Sekundenpiepston ab 30 Sekunden</span>
                        </label>
                    </p>
                    <button type="submit" className="btn">
                        zur Ampel
                    </button>
                </form>
                }
                {this.state.appActive &&
                    <Fragment>
                        <main className="main" style={this.state.color}>
                    <div className="time">{this.state.timerTime}</div>
                        <button type="button" onClick={this.startTimer} className="btn-large timer-buttons">
                            Start
                        </button>
                        <button type="button" onClick={this.resetTimer} className="btn-large timer-buttons">
                            Stop
                        </button>
                        </main>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default App;
